<template>
  <!-- Container Card -->
  <b-card
    no-body
  >
    <div class="m-2">
      <b-row>
        <!-- left align -->
        <b-col
          md="6"
          lg="6"
        >
          <b-card
            title="Save time and reduce no shows"
            class="mb-0"
          >
            <b-card-text>
              <ul>
                <li>Automatically collect full or partial payments at the time an event is scheduled.</li>
                <li>Allow your clients to pay with debit or credit card.</li>
              </ul>
              Requirements
              <ul>
                <li>A Stripe account that supports live charges</li>
              </ul>
            </b-card-text>

            <div class="mt-2">
              <b-overlay
                :show="busy"
                rounded
                opacity="0.4"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
                @hidden="onHidden"
              >
                <!-- <b-button v-if="profile && !profile.sessionID"
                  ref="connectButton"
                  variant="primary" 
                  :disabled="busy"
                  @click="upgrade"
                >
                  Upgrade to Pro Account
                </b-button> -->
                <b-button v-if="profile && profile.stripeComplete"
                  ref="connectButton"
                  variant="primary" 
                  :disabled="busy"
                  @click="disconnectStripe"
                >
                  Disconnect from Stripe
                </b-button>
                <b-button v-else
                  ref="connectButton"
                  variant="primary" 
                  :disabled="busy"
                  @click="connectStripe"
                >
                  Connect with Stripe
                </b-button>
              </b-overlay>
            </div>
            
          </b-card>
        </b-col>
        <b-col
          class="text-center mt-2"
          align-v="center"
          md="6"
          lg="6"
        >
          <b-row>
            <b-col
              md="4"
              lg="4"
              sm="4"
              xs="4"
            >
              <div class="profile-image mt-5">
                <b-avatar
                  size="96"
                  variant="light"
                  :src="require('@/assets/images/logo/logo.svg')"
                />
              </div>
            </b-col>
            <b-col
              md="1"
              lg="1"
              sm="1"
              xs="1"
              class="mt-3"
            >
              <feather-icon
                icon="PlusIcon"
                size="38"
                class="text-primary mt-4"
              />
            </b-col>
            <b-col
              md="5"
              lg="5"
              sm="5"
              xs="5"
            >
              <div class="profile-image mt-5">
                <b-avatar
                  size="96"
                  variant="light"
                  :src="require('@/assets/images/icons/stripe.svg')"
                />
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BCardTitle, BCardText, BButton, BAvatar, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getAuth } from "firebase/auth"
import { getFirestore, getDoc, doc} from 'firebase/firestore'
import { getFunctions, httpsCallable } from "firebase/functions"

const auth = getAuth()
const db = getFirestore()
const functions = getFunctions()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle, 
    BCardText,
    BButton,
    BAvatar,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      busy: false,
      profile: null,
    }
  },
  async mounted() {
    // TODO move to a vue store!!!!
    const profileRes = await getDoc(doc(db, "profiles", auth.currentUser.displayName))
    if(!profileRes.exists()) return
    this.profile = profileRes.data()
  },
  methods: {
    async connectStripe(){
      this.busy = true
      let accountUrl = null
      let stripeConnectResponse = null

      // Do we have a stripe accout in progress?
      if(this.profile.stripeAccount){
        const stripeConnectCheck = httpsCallable(functions, 'stripeCheckOnboarding')
        stripeConnectResponse = await stripeConnectCheck({ account: this.profile.stripeAccount, username: auth.currentUser.displayName })
        if(stripeConnectResponse && stripeConnectResponse.data && stripeConnectResponse.data.accountCompleted){
          this.profile.stripeComplete = true;
        }else if(stripeConnectResponse && stripeConnectResponse.data && stripeConnectResponse.data.url){
          accountUrl = stripeConnectResponse.data.url
        }
      }else{
        const stripeConnect = httpsCallable(functions, 'stripeAccountURL')
        const stripeResponse = await stripeConnect({ email: auth.currentUser.email, username: auth.currentUser.displayName, url: "https://hustleforge.com/profile/" + auth.currentUser.displayName })
        if(stripeResponse && stripeResponse.data && stripeResponse.data.url){
          accountUrl = stripeResponse.data.url
        }
      }

      // Do we have a URL
      if(accountUrl){
        this.$bvModal
          .msgBoxConfirm('Open a new page to continue the process of connecting to Stripe for Payments? (Make sure page this not blocked)', {
            title: 'Stripe Onboarding',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if(value) window.open(accountUrl, '_blank')
          })
      } 
      this.busy = false
    },
    disconnectStripe(){
      console.log('disconnectStripe')
      // TODO
    },
    upgrade(){
      this.$router.push('/pricing')
    },
    onHidden() {
      // Return focus to the button once hidden
      this.$refs.connectButton.focus()
    },
  }
}
</script>

<style lang="scss" scoped>
</style>

